import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'

const Hakkimizda = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)


  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Hakkımızda  ' />
      <Divider space='5' />

      <h3 style={{ color: "black", textAlign: "center" }}>Şirketimiz</h3>
      <div style={{ marginLeft: "20%", marginRight: "20%" }} >
        <div style={{ color: "black", fontSize: "18px" }}>&nbsp;&nbsp;&nbsp;&nbsp;2022 Yılında kurulmuş olup "BOXCAD YAZILIM, AMBALAJ TASARIM LTD. ŞTİ."  ismiyle ve "KUTU TASARIMI" markasıyla siz değerli müşterilerimize kaliteli bir hizmet sunmak için yazılım ve ambalaj bıçak izi çizimleri sektöründe deneyimli ekibiyle hizmetinize hazırdır.</div>
      </div>
      <h3 style={{ color: "black", textAlign: "center" }}>Vizyonumuz</h3>
      <div style={{ marginLeft: "20%", marginRight: "20%" }} >
        <div style={{ color: "black", fontSize: "18px" }}>&nbsp;&nbsp;&nbsp;&nbsp;Ambalaj çizimleri sektöründe, Türkiye’nin ve Dünya'nın bir numaralı ambalaj tasarımı ve bıçak izi çizimi şirketi olmak,
          Hizmet kalitesiyle müşterilerimizin birinci tercihi olarak sürekliliği sağlamak,
          amlabalaj çizimlerine dair kullanılabilecek en yeni teknolojiyi müşterilerimizin kullanımına sunmak ve ambalaj tasarımlarında dünya çapında etki oluşturmaktır. Çevresel duyarlılık bilinciyle atık malzeme israfını önemli ölçüde azaltmaktır.</div>
      </div>
      <h3 style={{ color: "black", textAlign: "center" }}>Misyonumuz</h3>
      <div style={{ marginLeft: "20%", marginRight: "20%" }} >
        <div style={{ color: "black", fontSize: "18px" }}>&nbsp;&nbsp;&nbsp;&nbsp;
          Amlabalaj kalıpları ve yazılım sektöründe deneyimli ekibiyle ambalaj çizimlerini en hızlı ve kolay bir şekilde üretip müşterilerimizin hizmetine sunmaktır.
          Böylece sistemimiz kaliteli,hızlı, yenilikçi ve kullanışlı tasarımlarla vektörel bıçak izi oluşturarak ambalaj üreticilerinin vazgeçilmezi haline dönüşecektir.
        </div>
      </div>
      <h3 style={{ color: "black", textAlign: "center" }}>Değerlerimiz</h3>
      <div style={{ marginLeft: "20%", marginRight: "20%" }} >
        <div style={{ color: "black", fontSize: "18px" }}>&nbsp;&nbsp;&nbsp;&nbsp;
          Müşteri odaklı, dürüst ve şeffaf, yenilikçi, yaratıcı, takım çalışmasına inanan, çalışanlarını sürekli geliştiren, çevre ve topluma duyarlı, sürdürülebilirliği destekleyen, dinamik.
        </div>
      </div>
      <h3 style={{ color: "black", textAlign: "center" }}>İlkelerimiz</h3>
      <div style={{ marginLeft: "20%", marginRight: "20%" }} >
        <div style={{ color: "black", fontSize: "18px" }}>
          Türkiye'nin bir şirketi olarak,<br />
          ülkemize duyduğumuz sevgi ile çalışırız.<br />
          Tecrübelerimizi<br />
          sizlerle paylaşmaktan,<br />
          müşterilerinize daha iyi bir hizmet sunmanız için<br />
          zamandan tasarruf sağlayarak <br />
          hızlıca çizimler üretir size destek oluruz.<br />
          Kalpleri kazanmayı hedefler,<br />
          Müşteri gizliliğine hassasiyet gösterir,<br />
          ilkelerimizden asla vazgeçmeyiz.<br />
          <h3 style={{ color: "black"}}>Çünkü Gücümüz sizsiniz!<br /></h3>
        </div>
      </div>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageHakkimizdaContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`

export default Hakkimizda
